import React from 'react';
import { Routes, Route, useLocation, Link } from 'react-router-dom';
import Toolbox from '../Toolbox/Toolbox';
import LessonPlans from '../LessonPlans/LessonPlans';
import styles from './Dashboard.module.scss';
import { FaRegCalendarAlt, FaUsers, FaChalkboardTeacher } from 'react-icons/fa';

const Dashboard = () => {
  const location = useLocation();
  const isMainDashboard = location.pathname === '/dashboard';

  const events = [
    { event: 'Sexual Health Webinar', icon: <FaRegCalendarAlt /> },
    { event: 'Consent Workshop', icon: <FaUsers /> },
    { event: 'Healthy Relationships Seminar', icon: <FaChalkboardTeacher /> },
  ];

  const announcements = [
    { date: 'July 10, 2024', announcement: 'New Interactive Flashcards Released!' },
    { date: 'July 25, 2024', announcement: 'Website Redesign Coming Soon!' },
    { date: 'August 1, 2024', announcement: 'New Lesson Plan on Consent and Boundaries Available!' },
  ];

  return (
    <div className={styles.dashboard}>
      <Routes>
        <Route path="toolbox" element={<Toolbox />} />
        <Route path="lesson-plans" element={<LessonPlans />} />
      </Routes>
      {isMainDashboard && (
        <div className={styles.mainDashboard}>
          <h2>Welcome to Your Dashboard, Demo!</h2>
          <p>Here are some recommendations to get you started:</p>

          <section className={styles.progress}>
            <h3>Your Progress</h3>
            <div className={styles.progressBar}>
              <div className={styles.progressFill} style={{ width: '70%' }}>70%</div>
            </div>
          </section>

          <div className={styles.columns}>
            <section className={styles.upcomingEvents}>
              <h3>Upcoming Events</h3>
              {events.map((event, index) => (
                <div key={index} className={styles.eventItem}>
                  <div className={styles.icon}>{event.icon}</div>
                  <p>{event.event}</p>
                </div>
              ))}
            </section>

            <section className={styles.announcements}>
              <h3>Announcements</h3>
              {announcements.map((announcement, index) => (
                <div key={index} className={styles.announcementItem}>
                  <p><strong>{announcement.date}</strong>: {announcement.announcement}</p>
                </div>
              ))}
            </section>
          </div>

          <section className={styles.recommendations}>
            <h3 className={styles.recommendationsHeader}>Recommended for You</h3>
            <p>Based on your progress, we recommend starting with the "Safe Sex Practices" lesson.</p>
          </section>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
