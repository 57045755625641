import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './LessonPlans.module.scss';

const sections = [
  {
    title: 'Consent and Healthy Relationships',
    content: 'Learn about consent and how to build healthy relationships.',
    link: '/consent-and-healthy-relationships-lesson',
    image: '/images/consent.png',
    tags: ['Consent', 'Relationships', 'Communication'],
    time: '45 minutes',
    difficulty: 'Intermediate',
  },
  {
    title: 'Sexual Orientation and Identity',
    content: 'Learn about sexual orientation and identity.',
    image: '/images/orientation.png',
    tags: ['Identity', 'Orientation'],
    time: '30 minutes',
    difficulty: 'Beginner',
  },
  {
    title: 'Anatomy and Physiology',
    content: 'Learn about anatomy and physiology.',
    image: '/images/anatomy.png',
    tags: ['Anatomy', 'Physiology'],
    time: '50 minutes',
    difficulty: 'Intermediate',
  },
  {
    title: 'Sexual Health',
    content: 'Learn about communicating safe sex practices and sexually transmitted infections (STIs).',
    link: '/safe-sex-lesson',
    image: '/images/health.png',
    tags: ['Health', 'STIs', 'Safety'],
    time: '60 minutes',
    difficulty: 'Advanced',
  },
  {
    title: 'Gender Identity and Expression',
    content: 'Learn about gender identity and expression.',
    image: '/images/gender.png',
    tags: ['Gender', 'Expression'],
    time: '40 minutes',
    difficulty: 'Intermediate',
  },
  {
    title: 'Puberty and Adolescent Sexual Development',
    content: 'Learn about puberty and adolescent sexual development.',
    image: '/images/puberty.png',
    tags: ['Puberty', 'Development'],
    time: '35 minutes',
    difficulty: 'Beginner',
  },
  {
    title: 'Interpersonal Violence',
    content: 'Learn about interpersonal violence.',
    image: '/images/violence.png',
    tags: ['Violence', 'Safety'],
    time: '50 minutes',
    difficulty: 'Advanced',
  },
];

const LessonPlans = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isCreatingCurriculum, setIsCreatingCurriculum] = useState(false);
  const [checkedSections, setCheckedSections] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [storedGroups, setStoredGroups] = useState([]);
  const [filteredSections, setFilteredSections] = useState(sections);

  useEffect(() => {
    // Load groups and curriculums from local storage if available
    const savedGroups = localStorage.getItem('groups');
    setStoredGroups(savedGroups ? JSON.parse(savedGroups) : []);
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleCreateCurriculum = () => {
    setIsCreatingCurriculum(!isCreatingCurriculum);
    setCheckedSections([]); // Reset checked sections when toggling
  };

  const handleCheckboxChange = (index) => {
    setCheckedSections((prevCheckedSections) =>
      prevCheckedSections.includes(index)
        ? prevCheckedSections.filter((i) => i !== index)
        : [...prevCheckedSections, index]
    );
  };

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
  };

  const handleAssign = () => {
    const updatedGroups = storedGroups.map(group => {
      if (group.name === selectedGroup) {
        return { ...group, curriculum: checkedSections };
      }
      return group;
    });
    setStoredGroups(updatedGroups);
    localStorage.setItem('groups', JSON.stringify(updatedGroups));
    setSelectedGroup('');
    setCheckedSections([]);
    setIsCreatingCurriculum(false);
  };

  const handleViewGroupCurriculum = (groupIndex) => {
    const group = storedGroups[groupIndex];
    const curriculumIndices = group.curriculum;
    const filtered = sections.filter((_, index) => curriculumIndices.includes(index));
    setFilteredSections(filtered);
  };

  useEffect(() => {
    const filtered = sections.filter((section) =>
      section.title.toLowerCase().includes(searchTerm)
    );
    setFilteredSections(filtered);
  }, [searchTerm]);

  return (
    <div className={styles.lessonPlans}>
      <h1>Lesson Plans</h1>

      <section className={styles.introduction}>
        <p>Welcome to the lesson plans page! Here you will find a variety of lesson plans designed to help you learn and foster healthy conversation.</p>
      </section>

      <div className={styles.topControls}>
        <button onClick={handleCreateCurriculum} className={styles.createCurriculumButton}>
          {isCreatingCurriculum ? 'Cancel' : 'Create Curriculum'}
        </button>
        {checkedSections.length > 0 && (
          <div className={styles.assignControls}>
            <select value={selectedGroup} onChange={handleGroupChange} className={styles.assignCurriculumDropdown}>
              <option value="" disabled>Select group</option>
              {storedGroups.map((group, index) => (
                <option key={index} value={group.name}>{group.name}</option>
              ))}
            </select>
            <button
              className={styles.assignButton}
              onClick={handleAssign}
              disabled={!selectedGroup}
            >
              Assign
            </button>
          </div>
        )}
      </div>

      <div className={styles.groupCurriculumButtons}>
        {storedGroups.map((group, index) => (
          group.curriculum && group.curriculum.length > 0 && (
            <button key={index} onClick={() => handleViewGroupCurriculum(index)} className={styles.viewCurriculumButton}>
              {group.name} Curriculum
            </button>
          )
        ))}
      </div>

      <div className={styles.searchContainer}>
        <input
          type="text"
          placeholder="Search lesson plans..."
          value={searchTerm}
          onChange={handleSearch}
          className={styles.searchInput}
        />
      </div>

      <div className={styles.sectionsContainer}>
        {filteredSections.map((section, index) => (
          <section key={index} className={styles.section}>
            {isCreatingCurriculum && (
              <input
                type="checkbox"
                className={styles.checkbox}
                checked={checkedSections.includes(index)}
                onChange={() => handleCheckboxChange(index)}
              />
            )}
            <div className={styles.sectionImage}>
              <img src={section.image} alt={section.title} />
            </div>
            <div className={styles.sectionContent}>
              <h2>{section.title}</h2>
              <p>{section.content}</p>
              <p className={styles.meta}>
                <span>Time: {section.time}</span> | <span>Difficulty: {section.difficulty}</span>
              </p>
              <div className={styles.tags}>
                {section.tags.map((tag, idx) => (
                  <span key={idx} className={styles.tag}>{tag}</span>
                ))}
              </div>
            </div>
            <div className={styles.footer}>
              <span className={styles.startNow}>Start Now</span>
              {section.link ? (
                <Link to={section.link} className={styles.arrowLink}>
                  <img src="/arrow-icon.png" alt="View Lesson" />
                </Link>
              ) : (
                <button className={styles.placeholderButton}>
                  <img src="/arrow-icon.png" alt="Placeholder" />
                </button>
              )}
            </div>
          </section>
        ))}
      </div>
    </div>
  );
};

export default LessonPlans;
