import React from "react"
import styles from './HomePage.module.scss'
import chatbotGraphic from '../../assets/chatbotGraphic.png'
import learningExperiences from '../../assets/learningExperiences.png'
import styled from "styled-components"
import Hero from './Hero'
import TextBlock from "./TextBlock"
import SubscribeBlock from "./SubscribeBlock"

function ImageRow() {
  return (
    <div className={styles.rowContainer}>
      <div className={styles.row}>
        <div className={`${styles.subImageContainer} ${styles.mobileImage}`}>
          <HeroImg src={ chatbotGraphic } style={{ float: 'right' }} />
        </div>
        <div className={styles.subTextContainer}>
          <h2 className={styles.titleText}>Ask Mobo Anything</h2>
          <p className={styles.text}>
            Have lots of questions but don't know where to turn? Mobo is built on nationally recognized sources instead of the world wide web to ensure healthy and accurate responses.
          </p>
        </div>
        <div className={`${styles.subImageContainer} ${styles.desktopImage}`}>
          <HeroImg src={ chatbotGraphic } style={{ float: 'right' }} />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.subImageContainer}>
          <HeroImg src={ learningExperiences } />
        </div>
        <div className={styles.subTextContainer}>
          <h2 className={styles.titleText}>Learn Everything</h2>
          <p className={styles.text}>
            Out-of-the-box interactive lessons and tools to help parents facilitate healthy conversations. 
          </p>
        </div>
      </div>
    </div>
  )
}

export default function HomePage ({ setModalOpen }) {
  return (
    <div>
      <Hero setModalOpen={setModalOpen} />
      <TextBlock />
      <ImageRow />
      <SubscribeBlock setModalOpen={setModalOpen} />
    </div>
  )
}

const HeroImg = styled.img`
  width: 100%;
`