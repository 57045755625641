import React from "react"
import styled from "styled-components"
import heroGraphic from '../../assets/heroGraphic.png'
import { Link } from 'react-router-dom'
import styles from './HomePage.module.scss'

export default function Hero({ setModalOpen }) {
  return (
    <div>
      <HeroText>Today's Sex Ed</HeroText>
      <SubHeroText style={{ textAlign: 'center', fontFamily: 'Roboto' }}>For Parents and Educators</SubHeroText>
      <HeroImage src={ heroGraphic } ></HeroImage>
      <ButtonRow>
        {/* <button 
          className={`${styles.button} ${styles.signUp}`}
          onClick={() => setModalOpen(true)}
        >
          Sign Up
        </button> */}
        <a href="https://docs.google.com/forms/d/e/1FAIpQLScot1wFsJjtknPdalo8zfQClDWSIGsJaSfzxgYlqG2HSfTMnw/viewform?usp=sharing" target="_blank" rel="noopener noreferrer">
          <button className={`${styles.button} ${styles.try}`}>Sign up for our waitlist here</button>
        </a>
      </ButtonRow>
    </div>
  )
}

const HeroText = styled.h1`
  text-align: center;
  /* text styling */
  font-style: normal;
  font-size: 84px;
  color: #1c2f72;
  margin-top: 3%;

  @media screen and (max-width: 1000px) {
    font-size: 72px;
  }

  @media screen and (max-width: 800px) {
    font-size: 60px;
  }

  @media screen and (max-width: 600px) {
    font-size: 48px;
  }
`

const SubHeroText = styled.h2`
  font-family: 'Graphik Bold';
  font-size: 40px;
  color: #ff9301;
  margin-bottom: 1%;

  @media screen and (max-width: 1000px) {
    font-size: 32px;
  }

  @media screen and (max-width: 800px) {
    font-size: 24px;
  }

  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
`

const HeroImage = styled.img`
  margin: 2% auto;
  width: 70%;
  display: block;

  @media screen and (max-width: 1000px) {
    width: 90%;
  }
`

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2%;

  @media screen and (max-width: 600px) {
    margin-top: 5%;
  }
`