import React from "react";
import styles from './Footer.module.scss';

export default function Footer () {
  return (
    <footer className={styles.footer}>
      <div className={styles.logoContainer}>
        <img src="/MoboLogo.png" alt="Mobo Logo" className={styles.logoImage} />
        <p className={styles.subLogo}>Today's sex ed</p>
      </div>
      <div>
        <a href="/contact" className={styles.contactButton}>Contact Us</a>
      </div>
    </footer>
  );
}
