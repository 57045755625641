import React from "react"
import styled from "styled-components"
import styles from './HomePage.module.scss'
import { Link } from 'react-router-dom';

export default function SubscribeBlock({ setModalOpen }) {
  return (
    <div>
      <h2 className={styles.blockText} style={{ textAlign: 'center' }}>
        Reinventing sexual education to prioritize relationships and communication.
      </h2>
      <div className={styles.buttonRow}>
        {/* <SubscribeBtn 
          className={styles.subscribeButton}
          onClick={() => setModalOpen(true)}
        >
          Join Our Waitlist
        </SubscribeBtn> */}
        <Link to="/demo">
          <SubscribeBtn>Talk to Mobo</SubscribeBtn>
        </Link>
      </div>
    </div>
  )
}

const SubscribeBtn = styled.button`
  border-radius: 50px;
  cursor: pointer;
  padding: 10px 100px;
  font-size: 20px;
  font-family: 'Roboto';
  margin: 0px 10px;
  color: white;
  background-color: #ff9301;
  border: none;

  &:hover {
    background-color: #1c2f72;
    color: white;
    transition: 0.3s;
  }

  @media screen and (max-width: 600px) {
    margin-top: 15px;
    font-size: 16px;
    padding: 10px 30px;
  }
`