import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './SafeSexLesson.module.scss';

const SafeSexLesson = () => {
  const [questions, setQuestions] = useState([]);
  const [questionInput, setQuestionInput] = useState('');
  const [answerInputs, setAnswerInputs] = useState({});

  const openNewWindow = () => {
    window.open(
      "https://www.figma.com/proto/KGlGkdi6rDEMKD5ReMrR2F/Navigating-safe-sex?node-id=1-2&starting-point-node-id=1%3A2&t=AuMzPN2qspmuXO6D-1",
      "SafeSexLesson",
      "width=1000,height=600,top=100,left=100,toolbar=no,menubar=no,scrollbars=yes,resizable=yes"
    );
  };

  const handleQuestionSubmit = () => {
    if (questionInput.trim()) {
      setQuestions([...questions, { question: questionInput, answers: [] }]);
      setQuestionInput('');
    }
  };

  const handleAnswerInputChange = (index, value) => {
    setAnswerInputs({ ...answerInputs, [index]: value });
  };

  const handleAnswerSubmit = (index) => {
    if (answerInputs[index]?.trim()) {
      const newQuestions = [...questions];
      newQuestions[index].answers.push(answerInputs[index]);
      setQuestions(newQuestions);
      setAnswerInputs({ ...answerInputs, [index]: '' });
    }
  };

  return (
    <div className={styles.safeSexLesson}>
      <div className={styles.card}>
        <div className={styles.content}>
          <h1>Sexual Health</h1>
          <p>Welcome to the Sexual Health lesson. Here you will learn about communicating safe sex practices and sexually transmitted infections (STIs).</p>
          <div className={styles.lessonInfo}>
            <p><strong>Time:</strong> 60 minutes</p>
            <p><strong>Difficulty:</strong> Advanced</p>
            <p><strong>Category:</strong> Health, STIs, Safety</p>
          </div>
          <button className={styles.openButton} onClick={openNewWindow}>
            Start Lesson
          </button>
          <div className={styles.forumSection}>
            <h2>Group Forum</h2>
            <div className={styles.askQuestion}>
              <ReactQuill
                value={questionInput}
                onChange={setQuestionInput}
                placeholder="Ask a question..."
                className={styles.inputField}
              />
              <div className={styles.buttonContainer}>
                <button onClick={handleQuestionSubmit} className={styles.questionButton}>Submit Discussion</button>
              </div>
            </div>
            <div className={styles.questions}>
              {questions.map((q, index) => (
                <div key={index} className={styles.question}>
                  <p className={styles.questionText}><strong>Q:</strong> <span dangerouslySetInnerHTML={{ __html: q.question }} /></p>
                  {q.answers.map((answer, aIndex) => (
                    <p key={aIndex} className={styles.answerText}><strong>A:</strong> <span dangerouslySetInnerHTML={{ __html: answer }} /></p>
                  ))}
                  <div className={styles.answerInput}>
                    <ReactQuill
                      value={answerInputs[index] || ''}
                      onChange={(value) => handleAnswerInputChange(index, value)}
                      placeholder="Provide an answer..."
                      className={styles.inputField}
                    />
                    <div className={styles.buttonContainer}>
                      <button onClick={() => handleAnswerSubmit(index)} className={styles.answerButton}>Submit Answer</button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SafeSexLesson;
