import React from 'react';
import styles from './ConsentAndHealthyRelationshipsLesson.module.scss';

const ConsentAndHealthyRelationshipsLesson = () => {
  const openNewWindow = () => {
    window.open(
      "https://www.figma.com/proto/iarN2T8sHQGphExYRe3gsT/Consent-and-healthy-relationships?node-id=1-2&t=Gs58liPNfQ1Cbokm-1&scaling=scale-down-width&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=1%3A2",
      "ConsentAndHealthyRelationshipsLesson",
      "width=800,height=600,top=100,left=100,toolbar=no,menubar=no,scrollbars=yes,resizable=yes"
    );
  };

  return (
    <div className={styles.consentAndHealthyRelationshipsLesson}>
      <div className={styles.content}>
        <h1>Consent and Healthy Relationships</h1>
        <p>Welcome to the Consent and Healthy Relationships lesson. Here you will learn about the importance of consent and how to build healthy relationships.</p>
        <button className={styles.openButton} onClick={openNewWindow}>
          Open Consent and Healthy Relationships Lesson
        </button>
      </div>
    </div>
  );
};

export default ConsentAndHealthyRelationshipsLesson;
