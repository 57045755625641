import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Toolbox.module.scss';
import { FaBoxOpen, FaClipboardList, FaChalkboardTeacher, FaBook, FaLaptopCode, FaTools } from 'react-icons/fa';

export default function Toolbox() {
  return (
    <div className={styles.toolbox}>
      <section className={styles.header}>
        <h1>Toolbox</h1>
        <p>Welcome to your personal Toolbox! Here you will find a variety of different Sexual Education tools that you can use to facilitate conversation.</p>
        <a href="https://learn-mobo.webflow.io/mobox" target="_blank" rel="noopener noreferrer" className={styles.orderLink}>
          <FaBoxOpen className={styles.icon} /> Order a MoBox
        </a>
      </section>
      
      <section className={styles.tools}>
        <h2>For 6-8th Grade</h2>
        <ul>
          <li>
            <Link to="/flash-cards" className={styles.toolLink}>
              <FaClipboardList className={styles.icon} /> Digital Flashcards
            </Link>
          </li>
          <li>
            <span className={styles.fakeLink}>
              <FaChalkboardTeacher className={styles.icon} /> Classroom Activities
            </span>
          </li>
          <li>
            <span className={styles.fakeLink}>
              <FaBook className={styles.icon} /> Educational Booklets
            </span>
          </li>
        </ul>
      </section>

      <section className={styles.tools}>
        <h2>For 9-10th Grade</h2>
        <ul>
          <li>
            <span className={styles.fakeLink}>
              <FaChalkboardTeacher className={styles.icon} /> Peer Education Programs
            </span>
          </li>
          <li>
            <Link to="/flash-cards" className={styles.toolLink}>
              <FaClipboardList className={styles.icon} /> Advanced Flashcards
            </Link>
          </li>
        </ul>
      </section>

      <section className={styles.tools}>
        <h2>For 11-12th Grade</h2>
        <ul>
          <li>
            <span className={styles.fakeLink}>
              <FaChalkboardTeacher className={styles.icon} /> Comprehensive Sex Ed Programs
            </span>
          </li>
          <li>
            <span className={styles.fakeLink}>
              <FaLaptopCode className={styles.icon} /> Online Workshops
            </span>
          </li>
          <li>
            <Link to="/flash-cards" className={styles.toolLink}>
              <FaClipboardList className={styles.icon} /> Expert-Level Flashcards
            </Link>
          </li>
        </ul>
      </section>

      <section className={styles.tools}>
        <h2>For Parents or Educators</h2>
        <ul>
          <li>
            <span className={styles.fakeLink}>
              <FaTools className={styles.icon} /> Administration and Management
            </span>
          </li>
        </ul>
      </section>
    </div>
  );
}
