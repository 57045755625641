// Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.scss';

const Header = ({ loggedIn, onLogout }) => {
  return (
    <header className={styles.header}>
      <Link to={loggedIn ? "/dashboard" : "/"} className={styles.logo}>
        <img src="/MoboLogo.png" alt="Mobo Logo" className={styles.logoImage} />
      </Link>
      <nav className={styles.nav}>
        {loggedIn ? (
          <>
            <Link to="/dashboard" className={styles.navLink}>Home</Link>
            <Link to="/dashboard/toolbox" className={styles.navLink}>Toolbox</Link>
            <Link to="/dashboard/lesson-plans" className={styles.navLink}>Lesson Plans</Link>
            <Link to="/settings" className={styles.navLink}>Settings</Link> {/* Add link to Settings */}
            <button onClick={onLogout} className={styles.navLink}>Log Out</button>
          </>
        ) : (
          <Link to="/login" className={styles.specialButton}>Log In</Link>
        )}
      </nav>
    </header>
  );
};

export default Header;
