// src/api.js
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.NODE_ENV === 'production'
    ? 'https://learn-mobo-641381d4670b.herokuapp.com/api/accounts'
    : 'http://localhost:8000/api/accounts',
  withCredentials: true
});

// Add an interceptor to set the authorization token
api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

export const loginUser = async (userData) => {
  try {
    console.log('Attempting login with:', JSON.stringify(userData));
    const response = await api.post('/login/', userData);
    console.log('Login response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Login error:', error.response ? error.response.data : error);
    throw error;
  }
};

export const fetchProtectedData = async () => {
  try {
    const response = await api.get('/some-protected-endpoint/');
    return response.data;
  } catch (error) {
    console.error('Error fetching protected data', error.response ? error.response.data : error);
    throw error;
  }
};