// src/components/FlashCards/FlashCards.js

import React from 'react';
import styles from './FlashCards.module.scss';

const FlashCards = () => {
  return (
    <div className={styles.flashCards}>
      <h1>Flash Cards</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://quizlet.com/923093940/match/embed?i=5cmdzj&x=1jj1" height="500" width="100%" style="border:0"></iframe>`,
        }}
      />
    </div>
  );
};

export default FlashCards;
