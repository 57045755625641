import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import Header from './components/Header/Header';
import HomePage from './components/HomePage/HomePage';
import Footer from './components/Footer/Footer';
import SafeSexLesson from './components/SafeSexLesson/SafeSexLesson';
import ConsentAndHealthyRelationshipsLesson from './components/ConsentAndHealthyRelationshipsLesson/ConsentAndHealthyRelationshipsLesson';
import FlashCards from './components/FlashCards/FlashCards';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import Toolbox from './components/Toolbox/Toolbox';
import Settings from './components/Settings/Settings';
import { loginUser } from './api'; // Import loginUser from the API service
import './App.scss';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = async (username, password) => {
    try {
      const userData = { username, password };
      const response = await loginUser(userData);
      if (response.token) {
        setLoggedIn(true);
        localStorage.setItem('token', response.token);
        if (process.env.NODE_ENV === 'production') {
          window.location.href = 'https://platform.learnmobo.com/dashboard';
        } else {
          navigate('/dashboard');
        }
      }
    } catch (error) {
      console.error('Login failed:', error);
      // Handle login error (e.g., show an error message to the user)
    }
  };

  const handleLogout = () => {
    setLoggedIn(false);
    localStorage.removeItem('token');
    if (process.env.NODE_ENV === 'production') {
      window.location.href = 'https://learnmobo.com';
    } else {
      navigate('/login');
    }
  };

  const ScrollToTop = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]);
    return null;
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const isPlatform = window.location.hostname === 'platform.learnmobo.com' || 
                       (process.env.NODE_ENV !== 'production' && token);
    setLoggedIn(!!token);

    if (isPlatform && !token) {
      navigate('/login');
    }
  }, [navigate]);

  const PublicRoutes = () => (
    <Routes>
      <Route path="/login" element={<Login onLogin={handleLogin} />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );

  const PrivateRoutes = () => (
    <Routes>
      <Route path="/dashboard/*" element={<Dashboard />} />
      <Route path="/safe-sex-lesson" element={<SafeSexLesson />} />
      <Route path="/consent-and-healthy-relationships-lesson" element={<ConsentAndHealthyRelationshipsLesson />} />
      <Route path="/flash-cards" element={<FlashCards />} />
      <Route path="/toolbox" element={<Toolbox />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/login" element={<Navigate to="/dashboard" />} />
      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>
  );

  return (
    <div className="appContainer">
      <ScrollToTop />
      <Header loggedIn={loggedIn} onLogout={handleLogout} />
      <div className="content">
        {window.location.hostname === 'platform.learnmobo.com' || (process.env.NODE_ENV !== 'production' && loggedIn) 
          ? <PrivateRoutes /> 
          : <PublicRoutes />}
      </div>
      <Footer />
    </div>
  );
}

export default App;
