import React, { useState, useEffect } from 'react';
import styles from './Settings.module.scss';

const Settings = () => {
  const [groupName, setGroupName] = useState('');
  const [inviteEmails, setInviteEmails] = useState('');
  const [groups, setGroups] = useState(() => {
    // Load groups from local storage if available
    const savedGroups = localStorage.getItem('groups');
    return savedGroups ? JSON.parse(savedGroups) : [];
  });

  const handleCreateGroup = (e) => {
    e.preventDefault();
    const newGroup = { name: groupName, emails: inviteEmails.split(',').map(email => email.trim()), curriculum: [] };
    const updatedGroups = [...groups, newGroup];
    setGroups(updatedGroups);
    localStorage.setItem('groups', JSON.stringify(updatedGroups)); // Save to local storage
    setGroupName('');
    setInviteEmails('');
  };

  const handleDeleteGroup = (index) => {
    const updatedGroups = groups.filter((_, i) => i !== index);
    setGroups(updatedGroups);
    localStorage.setItem('groups', JSON.stringify(updatedGroups)); // Save to local storage
  };

  return (
    <div className={styles.settingsContainer}>
      <h1 className={styles.title}>Settings</h1>
      <h2 className={styles.header}>Group Setup</h2>
      <form onSubmit={handleCreateGroup} className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="groupName" className={styles.label}>Group Name</label>
          <input
            type="text"
            id="groupName"
            className={styles.input}
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="inviteEmails" className={styles.label}>Invite Emails (comma separated)</label>
          <input
            type="text"
            id="inviteEmails"
            className={styles.input}
            value={inviteEmails}
            onChange={(e) => setInviteEmails(e.target.value)}
            required
          />
        </div>
        <button type="submit" className={styles.submitButton}>Create Group and Invite</button>
      </form>
      <div className={styles.groupList}>
        {groups.map((group, index) => (
          <div key={index} className={styles.groupItem}>
            <h3>{group.name}</h3>
            <p>Invited Emails:</p>
            <ul>
              {group.emails.map((email, idx) => (
                <li key={idx}>{email}</li>
              ))}
            </ul>
            <button className={styles.deleteButton} onClick={() => handleDeleteGroup(index)}>Delete Group</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Settings;
